$(document).ready(function(){
    getCarrouselData();
    getAboutUsMainData();
    getVideoCorp();
    getValues();
    getServices();
    getSectors();
});
//Carousel
$(document).on('click', '#carousel-indicators li', selectSlide);
var carrouselList = [];
var currentCarrouselIndex = 0;
var carrouselInterval = null;
function getCarrouselData(){
    GetMethodFunction('/web-api/carrousel/get',null, showCarrouselData, null);
}
function showCarrouselData(response){
    carrouselList = response.data;
    var appendHtml = '';
    for(var i = 0; i < carrouselList.length; i++){
        appendHtml += '<li data-slide-to="'+i+'" class="'+(i == 0 ? 'active' : '')+'"></li>';
    }
    $('#carousel-indicators').html(appendHtml);
    showCarrouselSlide();
    carrouselInterval = setInterval(function(){
        currentCarrouselIndex++;
        if(currentCarrouselIndex >= carrouselList.length){
            currentCarrouselIndex = 0;
        }
        showCarrouselSlide();
    }, 5000);
}
function showCarrouselSlide(){
    $('#carousel-container').css('background-image', 'url('+carrouselList[currentCarrouselIndex].path+')');
    $('#carrousel-title').html(carrouselList[currentCarrouselIndex].title);
    $('#carrousel-description').html(carrouselList[currentCarrouselIndex].subtitle);
    if(carrouselList[currentCarrouselIndex].button_text != null){
        $('#carrousel-action-button').show();
        $('#carrousel-action-button').html(carrouselList[currentCarrouselIndex].button_text);
        $('#carrousel-action-button').attr('href', carrouselList[currentCarrouselIndex].button_url);
    }else{
        $('#carrousel-action-button').hide();
    }
    //Active indicator
    $('#carousel-indicators li').removeClass('active');
    $('#carousel-indicators li[data-slide-to="'+currentCarrouselIndex+'"]').addClass('active');
}
function selectSlide(){
    clearInterval(carrouselInterval);
    currentCarrouselIndex = $(this).attr('data-slide-to');
    showCarrouselSlide();
}
//About us
function getAboutUsMainData(){
    GetMethodFunction('/web-api/about-us/get',null, showAboutUsMainData, null);
}
function showAboutUsMainData(response){
    console.log('showAboutUsMainData => ', response);
    $('#about-us-introduction-title').html(response.data.introduction_title);
    $('#about-us-introduction-description').html(response.data.introduction_description.replace(/\n/g, "<br>"));
}
//Corp videos
$(document).on('click', '#values-list-video-content #values-list-video-left-arrow', function(){
    currentVideoCorpIndex--;
    if(currentVideoCorpIndex < 0){
        currentVideoCorpIndex = videoCorpList.length - 1;
    }
    setCurrentVideoCorp();
});
$(document).on('click', '#values-list-video-content #values-list-video-right-arrow', function(){
    currentVideoCorpIndex++;
    if(currentVideoCorpIndex >= videoCorpList.length){
        currentVideoCorpIndex = 0;
    }
    setCurrentVideoCorp();
});
$(document).on('click', '#video-selectors-list i', function(){
    currentVideoCorpIndex = $(this).attr('value');
    setCurrentVideoCorp();
});
var videoCorpList = [];
var currentVideoCorpIndex = 0;
function getVideoCorp(){
    GetMethodFunction('/web-api/about-us/corp-video/get',null, showVideoCorp, null);
}
function showVideoCorp(response){
    videoCorpList = response.data;
    var appendHtml = '';
    for(var i = 0; i < videoCorpList.length; i++){
        appendHtml += '<i class="video-selectors fa-solid fa-circle" value="'+i+'"></i>';
    }
    $('#video-selectors-list').html(appendHtml);
    setCurrentVideoCorp();
}
function setCurrentVideoCorp(){
    $('#values-list-video-title').html(videoCorpList[currentVideoCorpIndex].title);
    $('#values-list-video-description').html(videoCorpList[currentVideoCorpIndex].description);
    $('#values-list-video-preview').attr('src', videoCorpList[currentVideoCorpIndex].url+'?loop=1&controls=0');
    $('#video-selectors-list i').removeClass('active-selector');
    $('#video-selectors-list i[value="'+currentVideoCorpIndex+'"]').addClass('active-selector');
}
//Get values
function getValues(){
    GetMethodFunction('/web-api/values/get',null, showValues, null);
}
function showValues(response){
    var valuesList = response.data;
    var appendHtml = '';
    $.each(valuesList, function(index, value){
        appendHtml += '<li class="values-list-item"><p>'+value.name+'</p></li>';
    });
    $('#values-list').html(appendHtml);
}
//Services
function getServices(){
    GetMethodFunction('/web-api/services/get',null, showServices, null);
}
function showServices(response){
    var servicesList = response.data;
    var appendHtml = '';
    $.each(servicesList, function(index, service){
        appendHtml += '<li class="services-list-item">';
            appendHtml += '<img src="'+service.path+'" alt="'+service.title+'" class="services-list-item-image">';
            appendHtml += '<h5 class="services-list-item-title">'+service.title+'</h5>';
        appendHtml += '</li>';
    });
    $('#services-list').html(appendHtml);
}
//Sectors
$(document).on('click', '#sectors-list .sectors-list-item', showSector);
var sectorsList = [];
var currentSectorIndex = 0;
function getSectors(){
    GetMethodFunction('/web-api/sectors/get',null, showSectors, null);
}
function showSectors(response){
    sectorsList = response.data;
    var appendHtml = '';
    $.each(sectorsList, function(index, sector){
        appendHtml += '<li class="sectors-list-item non-active-sector" value="'+index+'"><p>'+sector.title+'</p></li>';
    });
    $('#sectors-list').html(appendHtml);
    //click on first sector
    $('#sectors-list .sectors-list-item').first().click();
}
function showSector(){
    $('.active-sector').removeClass('active-sector').addClass('non-active-sector');
    $(this).addClass('active-sector');
    let index = $(this).attr('value');
    $('#sectors-info-description').html(sectorsList[index].description);
    //animate src change
    $('#sectors-info-image').fadeOut(100, function(){
        $('#sectors-info-image').attr('src', sectorsList[index].path);
        $('#sectors-info-image').fadeIn(100);
    });
}

